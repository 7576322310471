import React from "react";
import { Container } from "react-bootstrap";

const Disclosure = () => {
  return (
    <Container>
      <div className="p-5">
        <div className="p-5 fw-bold text-center">Disclosure</div>
        <p>
          BigSavePlus is a trusted platform dedicated to comparing and promoting
          various products and services, in collaboration with organizations and
          companies. It is important to be aware that BigSavePlus may receive
          compensation from these partners, which can influence how products are
          presented on their website.
        </p>
        <p>
          The content published by BigSavePlus is driven by data analysis,
          aiming to assist users in achieving savings and making well-informed
          decisions. However, it is crucial to understand that any opinions,
          analyses, reviews, or recommendations expressed on the website are
          solely those of the author and may not be endorsed by any issuer.
        </p>
        <p>
          Please note that the offers showcased on BigSavePlus may not encompass
          all companies or all available options in the marketplace. To access
          the terms and conditions of each product or service, kindly refer to
          the respective product owner's online application.
        </p>
        <p>
          While BigSavePlus endeavors to provide accurate information, they
          cannot guarantee its absolute accuracy. It is essential to bear in
          mind that BigSavePlus does not possess knowledge of your individual
          circumstances, and the information provided on their website is
          intended for general educational purposes only.
        </p>
      </div>
    </Container>
  );
};

export default Disclosure;
