import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./context/AuthContext";
import CouponProvider from "./context/couponContext";
import ReactGA from "react-ga4";
import UserProvider from "./context/UserContext";

ReactGA.initialize("G-JGLY4QGCSD");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider >
      <CouponProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </CouponProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
