import React from "react";
import Arrow from "../assets/Arrow";
import Toast from "./Toast";

const CouponCode = ({ toast, clipper, couponCode }) => {
  return (
    <div
      className="d-flex flex-column  justify-content-center w-100"
      style={{ margin: "44.8px 59.9px" }}
    >
      <div className="d-flex flex-row gap-2 py-2 justify-content-center fw-bold">
        <div>Here is your coupon</div>
        <div>
          <Arrow />
        </div>
      </div>
      {toast ? <Toast /> : null}
      <div
        onClick={clipper}
        className="d-flex flex-row justify-content-between align-items-center p-2 rounded"
        style={{
          border: "1px dashed black",
          cursor: "pointer",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path d="M272 0H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128H192v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" />
        </svg>
        <div className="" style={{ fontSize: "1.2rem" }}>
          {couponCode}
        </div>
        <button
          onClick={clipper}
          style={{
            background: "black",
            color: "white",
            border: "none",
            padding: "4px 6px",
            borderRadius: "5px",
          }}
        >
          Click to Copy
        </button>
      </div>
    </div>
  );
};

export default CouponCode;
