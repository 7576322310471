import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCoupon } from "../../context/couponContext";
import decryptText from "../../utils/decryptText";

const Headers = () => {
  const { company } = useParams();
  const {
    setCouponCode,
    setOfferpercent,
    setValidating,
    setProduct_hash,
    setFromNumber,
    image,
    setImage,
    setRedirecturl,
  } = useAuth();

  const {
    call_conf_ans_function,
    setCall_conf_ans_function,
    submit_function,
    setSubmit_function,
  } = useCoupon();

  const location = useLocation();

  // const regex = new RegExp(/^\?[a-zA-Z]=\w+$/);
  const key = process.env.REACT_APP_URL_PARAMETER;

  const fetchCouponDetails = async () => {
    // Parse Query string
    const parsed = queryString.parse(location.search);

    try {
      // checking the status of vip coupon and redirecting to main route if not find coupon and if found the coupon then adding details to global variable
      const res = await axios.get(
        `https://apiz.adam-app.xyz/v1/coupons?couponName=${company}`
      );

      if (res?.data?.message === "Coupon details retrieved successfully.") {
        const data = res?.data?.data;
        if (data?.Is_Premium === true) {
          // checking premium or normal
          if (
            data?.status === "1" &&
            parsed["key"] === queryString.parse(key)["key"]
          ) {
            // checking status code for premium
            // adding data of premium coupon
            setValidating(false);
            setRedirecturl(decryptText(data?.redirect_url));
            setCouponCode(decryptText(data?.product_coupon));
            setOfferpercent(data?.offer);
            setProduct_hash(data?.product_hash);
            setFromNumber(data?.fromPhone);
            setImage(require("../../companies/" + data?.image));
            setCall_conf_ans_function(
              decryptText(data?.call_conf_ans_function)
            );
            setSubmit_function(decryptText(data?.submit_function));
          } else {
            //status false redirect to home
            window.location.href = "/";
          }
        } else {
          //adding data if normal coupon
          setCouponCode(data?.product_coupon);
          setOfferpercent(data?.percent);
          setFromNumber(data?.fromPhone);
          setImage(require("../../companies/" + data?.image));
        }
      } else {
        // redicting to home if coupon not found
        window.location.href = "/";
      }
    } catch (error) {
      window.location.replace("/");
    }
  };

  useEffect(() => {
    fetchCouponDetails();
  }, []);

  return (
    <img
      alt="images"
      src={image}
      style={{
        height: "200px",
        width: "auto",
        objectFit: "contain",
      }}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop
        e.target.src = "/default-image.jpg"; // Replace broken image with a default image URL or local path
      }}
    />
  );
};

export default Headers;
