import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCoupon } from "../context/couponContext";
import SpinnerRoundOutlined from "../package/SpinnerRoundOutlined";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useUserIpDetails } from "../context/UserContext";

import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";

const MyModal = ({ showModal, closeModal, CTA, phone, postingData }) => {
  // User IP Details
  const { userIpDetails } = useUserIpDetails()

  const { submit_function } = useCoupon();
  const { isDisable, phoneNumber } = useAuth();
  const [isDone, setIsDone] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsDone(true);
    }, 6000);
  }, []);
  if (submit_function === "Verifying Error") {
    if (phone !== "" && isDisable === false) {
      postingData();
    }
    return (
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Phone Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="fs-3 fw-bold pt-3 text-black">
              {isDone ? (
                "Something Went Wrong!"
              ) : (
                <SpinnerRoundOutlined height={"100px"} width={"100px"} />
              )}
            </div>
            {isDone ? (
              <div
                role="button"
                onClick={closeModal}
                className="my-8 bg-black border border-dark rounded-pill text-white fs-5 py-2 text-center"
                style={{ width: "150px" }}
              >
                Close
              </div>
            ) : (
              <div>Verifyng</div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Phone Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div>
              Press "Call Me" when you are ready to verify.
            </div>
            <div className="fs-3 fw-bold pt-3 " style={{ color: "#0011f4" }}>
              <p>{formatPhoneNumber(phoneNumber)}</p>
              {/* <PhoneInput 
                initialValueFormat="national"
                value={phone} 
                defaultCountry={userIpDetails?.country_code} 
                style={{ paddingLeft: '13%'}} 
              /> */}
            </div>
            <div
              role="button"
              onClick={closeModal}
              className="py-4"
              style={{ textDecoration: "underline" }}
            >
              Edit your number
            </div>
            <Link>
              <button
                onClick={(event) => CTA(event)}
                className="bg-black border border-dark rounded-pill text-white fs-5 py-2"
                style={{ width: "150px" }}
              >
                Call Me
              </button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default MyModal;