import { useEffect, useState } from "react";
import DOMPurify from "dompurify";

const EmailComponent = ({ handleSubmit, handledClick, setUltimateEmail }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    setUltimateEmail(email);
  }, [email]);

  const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setEmailError("Email is required");
    } else if (!regex.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateEmail(e.target.value);
    const sanitizedEmail = DOMPurify.sanitize(email);
    validateEmail(sanitizedEmail);
    if (!emailError && sanitizedEmail !== "" && emailError === "") {
      handledClick();
    }
  };

  return (
    <>
      <div className="fw-bold fs-6">Enter Email Address</div>
      <form onSubmit={onSubmit} className="w-100">
        <input
          name="email"
          id="email"
          autoComplete="on"
          className={`p-2 w-100 my-3 border rounded ${
            emailError && "is-invalid"
          }`}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
          placeholder="Email"
          value={email}
        />

        {emailError && <div className="invalid-feedback">{emailError}</div>}

        <input
          type="submit"
          value="continue"
          className="bg-black border border-dark rounded-pill text-white fs-5 py-2 w-100 mt-2"
        />
      </form>
      <span className="text-center my-2" style={{ fontSize: "0.4em" }}>
        I agree to the Terms & Conditions and Privacy Policy.
      </span>
    </>
  );
};

export default EmailComponent;
