import React from "react";
import Card from "./Card";
import { background } from "./common";
import { Link } from "react-router-dom";
import { useCoupon } from "../context/couponContext";

export const Hot = () => {
  const { coupons } = useCoupon();

  return (
    <section id="hot" className="py-5" style={{ background: background.hot }}>
      <div
        className="fw-bold fs-2 d-flex row justify-content-center py-4"
        style={{ paddingTop: "100px" }}
      >
        Hot Coupons
      </div>
      <center>
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-3 ">
            {coupons.slice(6, 10).map((items) => {
              const image = require("../companies/" + items.image);
              return (
                <div className="col">
                  <Link
                    to={`/offer/${items.name}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card image={image} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </center>
    </section>
  );
};
