import React from "react";

const Toast = () => {
  return (
    <span
      style={{
        color: "green",
        position: "absolute",
        zIndex: 1,
        marginTop: "-50px",
      }}
    >
      Copied
    </span>
  );
};

export default Toast;
