import SpinnerRoundOutlined from "../package/SpinnerRoundOutlined";
import { useAuth } from "../context/AuthContext";
import { useCoupon } from "../context/couponContext";
// import { useState } from "react";
const CallWaiting = ({ twilio, isCalling }) => {
  const { image, offerpercent } = useAuth();
  // const [hide, setHide] = useState(false);
  const { twilioResponse, setTwilioResponse } = useCoupon();
  return (
    <div className="d-flex flex-column align-items-center justify-content-center gap-2 vh-100 vw-100">
      <div>
        <img
          alt="images"
          src={image}
          style={{
            height: "200px",
            width: "auto",
            objectFit: "contain",
          }}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = "/default-image.jpg"; // Replace broken image with a default image URL or local path
          }}
        />
      </div>
      <div className="fs-1 -mt-5">{isCalling ? '' : offerpercent}</div>
      <SpinnerRoundOutlined height={"100px"} width={"100px"} />
      <div
        style={{ display: twilioResponse ? "none" : "inline", color: "gray" }}
      >
        We are calling your phone to verify. Please Answer
      </div>
      <div
        role="button"
        className={`fw-bold`}
        style={{
          display: twilioResponse ? "inline" : "none",
          fontSize: "0.8em",
        }}
        onClick={() => {
          twilio();
          setTwilioResponse(false);
        }}
      >
        Didn't recieve a call? Click here to try again.
      </div>
    </div>
  );
};

export default CallWaiting;
