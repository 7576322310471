import { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext({});

export const useUserIpDetails = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [userIpDetails, setUserIpDetails] = useState(null)

// Function to update user ip-details
    const updateUserIPDetails = (ipDetails) => setUserIpDetails(ipDetails)

    let values = {
        userIpDetails,
        updateUserIPDetails
    }

  return <UserContext.Provider 
        value={values}>
        {children}
    </UserContext.Provider>;
};

export default UserProvider;
