import NavigationBar from "./components/NavigationBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Home from "./components/Home";
import { Hot } from "./components/Hot";
import { New } from "./components/New";
import { Deal } from "./components/Deal";
import TermsAndConditions from "./components/terms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import OfferName from "./components/OfferName";
import Disclosure from "./components/Disclosure";
import TestOffer from "./components/TestOffer";
import Blog from "./components/blog1";
import Crypto from "./components/crypto";

import ScrollToTop from "./package/ScrollToTop";
import SpinnerRoundOutlined from "./package/SpinnerRoundOutlined";

import { useAuth } from "./context/AuthContext";
import { useCoupon } from "./context/couponContext";

import { clarity } from "clarity-js";

clarity.start({
  projectId: "htbj5cfswa",
  upload: "https://m.clarity.ms/collect",
  track: true,
  content: true,
});

const queryClient = new QueryClient();

const HomeRedirect = () => {
  window.location.replace("/");
};

const getDisplayBasedOnLoader = (loaderAtgeneral) => {
  return loaderAtgeneral ? "inline" : "none";
};

function Main() {
  const { loaderAtgeneral } = useCoupon();

  return (
    <>
      <div
        className="main-content"
        style={{
          display: getDisplayBasedOnLoader(loaderAtgeneral),
          background: "#f8f9fa",
          paddingBottom: "20px",
          position: "relative",
          cursor: "pointer",
          width: "100vw",
        }}
      >
        <Hero />
        <Home />
        <Hot />
        <New />
        <Deal />
      </div>
      <div
        style={{
          height: "100vh",
          display: loaderAtgeneral ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SpinnerRoundOutlined height={"80px"} width={"80px"} />
      </div>
    </>
  );
}

function App() {
  const { showHeaderFooter, setShowHeaderFooter, loaderAtgeneral } =
    useCoupon();
  const commonDisplay = getDisplayBasedOnLoader(loaderAtgeneral);

  return (
    <QueryClientProvider client={queryClient}>
      <div id="app-root">
        {" "}
        {/* Make this the main flex container */}
        <BrowserRouter>
          <ScrollToTop />
          {showHeaderFooter && (
            <div
              style={{
                position: "fixed",
                zIndex: 1,
                cursor: "pointer",
                display: commonDisplay,
              }}
            >
              <NavigationBar />
            </div>
          )}
          <div className="main-content">
            {" "}
            {/* This div will grow to take up all available space */}
            <Routes>
              <Route path="/" element={<Main />} />
              <Route
                path="/terms-and-condition"
                element={<TermsAndConditions />}
              />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/offer/:company" element={<OfferName />} />
              <Route path="/offer-test" element={<TestOffer />} />
              <Route path="/disclosure" element={<Disclosure />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/crypto-blog" element={<Crypto />} />
              <Route path="*" element={<HomeRedirect />} />
            </Routes>
          </div>
          <div style={{ display: commonDisplay }}>
            {showHeaderFooter && <Footer />} {/* Footer */}
          </div>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
