import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container>
      <div className="p-5">
        <div className="p-5 fw-bold text-center">Privacy</div>
        <p>
          By continuing to use our services on or after January 1, 2023, you are
          agreeing to the updated Privacy Policy below.
        </p>
        <h6>Effective from January 1, 2023.</h6>
        <p>
          This Privacy Policy is the bigsaveplus.com website, including mobile
          website.
        </p>
        <h6>1. Introduction</h6>
        <p>
          Thank you for using our Services! Your trust is important to us and we
          are committed to protecting the privacy and security of your personal
          information. This information is used to provide you with a great
          shopping experience. Our privacy and data teams are committed to
          protecting the personal information we collect and helping to ensure
          that personal information is handled properly.
        </p>
        <p>
          This Privacy Policy explains how BigSavePlus Ventures., operating as
          bigsaveplus.com Incorporated (together with our subsidiaries,
          "BigSavePlus Ventures," "we" or "our"), collects, uses, and discloses
          your personal information. This Privacy Policy applies to the
          bigsaveplus.com website and other services we own or control that link
          to or reference this Privacy Policy (the "Services").
        </p>
        <p>
          By using any of the Services, you are agreeing to the terms of this
          Privacy Policy and the Terms of Use into which it is incorporated. If
          you do not agree to this Privacy Policy, you should not use any of the
          Services or provide to us any of your personal information.
        </p>
        <p>
          If you have questions regarding this Privacy Policy, please contact us
          via email at privacy@bigsaveplus.com or in writing at the following
          address:
        </p>
        <p>Big Save Plus Venture</p>
        <p>206 Chapman Rd 205</p>
        <p>#4 Newark, DE 19702</p>
        <p>Attn: Privacy</p>
        <p>
          If you have an unresolved privacy or data use concern that we have not
          addressed to you , please contact our U.S.-based third-party dispute
          resolution provider (free of charge) 
          https://feedback-form.truste.com/watchdog/request.
        </p>
        <p>
          Our Services may include links to third-party websites, applications
          and services, and embedded third-party tools and widgets. Any
          third-party collection and use of your information are governed by the
          privacy policies of the respective third parties. We encourage you to
          learn about the privacy practices of those third parties.
        </p>
        <h6>2. Updates</h6>
        <p>
          We may update this Privacy Policy to reflect changes to our
          information practices. If we make any material changes, including
          changes to how we disclose personal information, we will notify you by
          email or by providing notice on or through the Services prior to the
          change becoming effective.
        </p>
        <h6>3. What Information Do We Collect and Use?</h6>
        <p>(a) Information You Provide</p>
        <p>
          If you create any type of account, download software, or participate
          in certain activities on the Services (such as surveys, sweepstakes,
          contests, or other promotions), we may ask you to provide us with
          personal information that can be used to specifically identify,
          locate, or contact you, such as: your full name, email address, postal
          mailing address, or telephone number. You may also have the option of
          providing us with other information about yourself or your household
          that may be associated with information that identifies you, such as,
          but not limited to, retailer loyalty ID number, retailer online
          account information (including user ID and password), age or date of
          birth, gender, interests, product purchase information or receipts,
          zip code, product or content preferences (including saved offers and
          content favorites), comments and reviews, as well as any other
          information you may choose to provide.
        </p>
        <p>
          When you submit a copy of your receipt, you are consenting to our
          collection of all the information contained in the receipt, such as
          the items purchased, quantity of items purchased, price of each item
          purchased, the total costs of purchases, any discounts applied,
          information about the store where the purchase was made (such as store
          ID, name, and address), and purchase method (cash, credit card, gift
          card).
        </p>
        <p>
          If you add a retailer loyalty ID number to your account or your
          retailer online account access information, you are agreeing to
          disclose, and authorizing us to use your loyalty ID number or account
          information to collect information about your shopping activity
          in-store and/or online either directly from the retailer or by
          accessing your online account with a retailer.  This information may
          include historical (up to two years, if available) and future purchase
          data, including products purchased, quantities purchased, prices
          charged, where you made the purchases (for example, store name, store
          location, website address), and the time and date of your purchases.
          We do not use your loyalty ID number or online account information to
          collect data about you that is not normally provided on a purchase
          receipt, such as full payment card account information (account
          number, expiration date, security code, or billing address), or bank
          account numbers.
        </p>
        <p>
          It will always be your decision whether to directly provide to us your
          personal information. In some cases, if you decline to provide
          personal information to us, then we may not be able to provide you
          with certain Services, and you may not be eligible for coupons, cash
          back rewards, rebates, sweepstakes, contests, or other promotions. For
          example, in order to use our Cash Back Offers, you must provide your
          financial account information, in addition to other requirements.  For
          information about how we handle user-submitted content (for example,
          posts, comments, or reviews), see Section 8.
        </p>
        <h6>
          (b) Information Automatically Collected As You Access and Use the
          Services
        </h6>
        <p>
          When you use or interact with the Services in any way, we may collect
          information automatically from you.  To collect this information, we
          may use cookies, web beacons, pixel tags, log files, local storage,
          and other similar mechanisms to automatically collect information. 
          The information we automatically collect includes:
        </p>
        <p>
          Your activity on the Services (such as subject of advertisements
          clicked or scrolled over, content viewed, searches performed, and
          features accessed);
        </p>
        <p>Operating system, web browser information, and device attributes;</p>
        <p>Inbound-referral and outbound websites;</p>
        <p>
          Your internet protocol ("IP") address, from which we may infer your
          geographic location, and other information;
        </p>
        <p>
          The geo-location of your device (using GPS or other geo-location
          data);
        </p>
        <p>
          How you interact with offers (e.g. coupons and cash back offers),
          advertisements, and other content available on or through the
          Services, such as whether you print a coupon or load or save an offer
          to your user account or to a retailer loyalty ID;
        </p>
        <p>
          The dates and times that you visited or used the Services, or
          requested certain services or transactions;
        </p>
        <p>
          Location information (see Location Services in Section 3.e below); and
        </p>
        <p>Device or advertising identifier associated with your device.</p>
        <p>
          An identifier or personal information may be associated with the
          information collected using cookies and/or similar technologies. If
          you are accessing the Services from a mobile device, see Section 3.e
          for additional automatically collected information.
        </p>
        <p>
          Device Information and Identifier. When you use our Services or
          install on your computer or other device our coupon printing software,
          mobile applications, or other applications, we may collect and store
          information about that device that helps us manage our products and
          services, deliver a more customized experience for you (including
          enabling the delivery of more advertising on and off our Sites), and
          detect and prevent fraud. If you download the coupon printing
          software, a device identifier remains associated with that device even
          after uninstalling the software. This identifier is used primarily to
          prevent fraud. If you use our Services on your device, we will create
          an identifier for your activity, and a persistent identifier will be
          associated with any device you authenticate using your phone number.
        </p>
        <p>
          Third-Party Cookies and Similar Technologies. We allow third-party
          companies to use cookies, pixels or other similar technologies on our
          Services to collect and use information such as hashed data,
          clickstream information, browser type, time and date, subject of
          advertisements clicked or scrolled over, content viewed, search terms,
          location of the device, a cookie identifier, communication type
          (broadband or dial-up), and browser-related properties. These third
          parties include advertisers, service providers, advertising partners,
          affiliate-marketing networks and other companies with whom we partner
          to provide certain offers, promotions or features available on or
          through our Services or third-party properties, or to display
          advertising on the Services or third-party properties. Such third
          parties may use cookies to provide advertisements about goods and
          services likely to be of greater interest to you, to track off-Site
          transactions and to assist us in providing, evaluating, and improving
          the Services and our other products and services.  Further, such third
          parties may use information they directly collect from you in
          combination with information we provide (as described in Section 5) or
          information they collect from another source. In some cases, we may
          not have access to or control of the third-party tracking technology.
        </p>
        <p>
          Log Files and Local Storage (HTML5/Flash Cookies). As is true of most
          websites, we gather certain information automatically and store it in
          log files. This information may include IP addresses, browser type,
          internet service provider ("ISP"), referring/exit pages, operating
          system, date and time stamp, clickstream data and other data. We use
          this information to manage and operate our products and services, to
          offer you more personalized content and advertising, and for the other
          purposes described in Section 4 below.
        </p>
        <p>
          Analytics. We may use our own and third-party tracking and analytics
          tools to better understand who is using our Services and how they are
          using them. These tools may use cookies and page tags to collect
          information such as time of visit, pages visited, time spent on each
          page of the website, IP address, and type of operating system used.
          One of the analytics tools we may use is Google Analytics.
        </p>
        <h6>(c) Information From Third Parties</h6>
        <p>
          We may receive information from third parties (including service
          providers, retailers, data partners and other business partners) about
          you, including personal information, cookies and other identifiers,
          information about in-store and online transactions, and demographic
          and household data. We may use this information to administer the
          applicable program or service, to offer you more personalized content
          and advertising (including by our customers and other third parties),
          and for the other purposes described in Section 4 below.
        </p>
        <p>
          For the purpose of helping you receive, in real-time or with
          real-world context, advertisements that are relevant to you based on
          your past or present location or experience, we may also collect
          precise location data ("PLD"), which is information that describes the
          geographic location of your device to within 1850 feet or less, such
          as GPS-level latitude-longitude coordinates or location-based Wi-Fi
          triangulation.
        </p>
        <p>
          When PLD is collected from third parties like mobile application
          publishers, those publishers are solely responsible for providing a
          mechanism through which you may enable or disable the collection of
          PLD from your device.
        </p>
        <h6>(d) Information You Provide About a Third Party</h6>
        <p>
          If you choose to use any of our referral services to tell someone else
          about our Services, or to email a promotion, we will ask you for that
          person’s name and email address. We will use the information you
          provide to automatically send to that person the referral, such as an
          email inviting him or her to the Services you recommended. We do not
          disclose this email to any third party, other than those third-party
          service providers used to transmit the referral.
        </p>
        <h6>
          (e) Information From Use Of Our Mobile Websites and Applications
        </h6>
        <p>
          In addition to other information described above, when you download or
          use one of our mobile applications, or access the Services through a
          mobile device, we may automatically collect information about your
          mobile device, including, but not limited to, your operating system,
          device type, and mobile device advertising identifier.  We may request
          access to your location, such as GPS, Bluetooth, Wi-Fi and cell tower
          information to determine your approximate location. We may also
          request access to the device storage, camera, microphone and other
          features, and permission to send you push notifications. You may
          decline to provide location information, storage, camera and
          microphone access, and push notification permission at any time using
          your mobile device’s system settings. Please consult your mobile
          device’s instruction manual for additional information about
          controlling system and application settings.
        </p>
        <p>
          Location Services. Our mobile applications ("App") may incorporate
          software from or access information from your mobile device for the
          App to provide certain location-based services. If you have location
          services enabled on your device for our App, we may use your location
          information to provide you tailored offers and ads and push
          notifications based, if push notifications are enabled.
        </p>
        <p>
          Mobile Analytics. We use mobile analytics software to allow us to
          better understand the usage and performance of our mobile software on
          your phone. This software may record information such as how often you
          use the application, the events that occur within the application,
          usage, performance data, and where the application was downloaded
          from. We may link the information collected by the analytics software
          to either an account identifier, or a mobile device advertising
          identifier.
        </p>
        <h6>4. How We Use Your Information</h6>
        <p>
          We may use information that we collect for the following purposes:
        </p>
        <p>Provide Services to you;</p>
        <p>Setting up and managing your account;</p>
        <p>
          Operate, maintain, protect, improve, and optimize the Services and our
          other products and services;
        </p>
        <p>
          Communicate with you about coupons, cash back rewards, advertisements,
          promotional offerings, and other content found on and off our
          Services, including through push notifications, emails, and text
          messages, if you have given your consent to these receive any of these
          types of communications;
        </p>
        <p>
          Process and facilitate coupon printing, loading, and redemption
          activity;
        </p>
        <p>
          Process redemption activity and payment of cash back rewards or
          rebates; Manage and store shopping lists;
        </p>
        <p>
          Send you support messages, updates, security alerts, and account
          notifications;
        </p>
        <p>Detect and prevent fraud and other harmful activity;</p>
        <p>Conduct security investigations and risk assessments;</p>
        <p>Invite you to events and relevant opportunities;</p>
        <p>
          Monitor, measure, conduct analytics on, and improve marketing,
          advertising and promotional campaigns and efforts.
        </p>
        <p>
          Provide you with a personalized experience on and off the Services,
          including targeted advertisements and promotions of our customers,
          partners and other third parties, which are distributed by or through
          us or our advertising partners, that may be of interest to you based
          on your preferences, demographic data, purchase history, and
          interactions with and activities on our Services.
        </p>
        <p>
          Enable the distribution of promotions, marketing, and advertising
          content.
        </p>
        <p>
          Advertising, including personalized advertising, through social media
          platforms such as Facebook, Instagram, Pinterest, or TikTok.
        </p>
        <p>
          Determine eligibility for certain promotions, coupons, rebates, or
          other offerings on our Services.
        </p>
        <p>
          Anonymize and aggregate information for different purposes, such as
          website traffic flow analysis, usage analysis, product development,
          market analysis, case studies, reports, insights, and marketing.
        </p>
        <p>
          For administrative purposes, such as sweepstakes administration or
          responding to your inquiries.
        </p>
        <p>
          Conduct surveys (e.g., customer satisfaction, feedback) that may be
          used to get to know you better and provide us with valuable insight
          that can help us offer solutions that meet your needs and
          expectations.
        </p>
        <p>
          Publish your product reviews on our Services, including in our mobile
          applications, and as permitted, to disclose your comments to our
          partners for their use and to establish the authenticity of the
          comments.
        </p>
        <p>Provide customer service.</p>
        <p>Comply with local, national, and international laws.</p>
        <p>Disclose information as described in this Privacy Policy.</p>
        <p>
          We may combine information about you collected across different
          Services and devices, and we may combine that information with
          information obtained from third parties. We may make inferences about
          you, such as product preferences and interests, based on this combined
          information. We and our advertising and measurements partners use the
          combined information and inferences to help our customers, partners
          and other third parties deliver, and measure tailored promotions and
          advertising whether on our Services or elsewhere.
        </p>
        <p>
          We retain all information we collect about you for the period
          necessary to fulfill the purposes outlined in this Privacy Policy
          unless a longer retention period is required or permitted by law.
        </p>
        <h6>5. When We Disclose Information</h6>
        <p>
          (a) We may disclose personal information and other information
          collected or obtained about you to our affiliates (subsidiaries or
          other companies owned or controlled by RetaileCommerce).  In all
          cases, the affiliates will only be able to use the information as set
          forth in Section 4 above.
        </p>
        <p>
          (b) We may work with other companies to operate, maintain, and deliver
          the Services and the content, services and features available on and
          off the Services, including to provide personalized promotions and
          advertisements, for contests and sweepstakes, and to communicate with
          you by email, text message, or push notification. In such
          circumstances, those other companies will have access to the
          information needed, including personal information, to provide the
          applicable service, content, or feature on our behalf, but they will
          not be permitted to use the information for any other purpose, except
          for the aggregation and other purposes set forth in Section 5.e. For
          instance, if you provide us a retailer loyalty ID number to load a
          coupon to your loyalty account, we will provide the retailer with the
          loyalty ID number and other information necessary to fulfill your load
          request. The retailer may then match this information with other
          information in its possession.
        </p>
        <p>
          (c) We work with third-party partners, such as publishers, data
          providers, and content providers ("Advertising and Measurements
          Partners"). We may disclose information (such as your interactions
          with our Services and transaction information), as well as inferences
          derived from that information (such as inferences regarding product
          preferences and interests), to these Advertising and Measurements
          Partners. Our Advertising and Measurements Partners may use the
          information we disclose to them for their own purposes. We and our
          Advertising and Measurements Partners also use the combined
          information and inferences to help partners and other third parties
          deliver, and measure tailored promotions and advertising whether on or
          off our Sites.
        </p>
        <p>
          (d) We may disclose personal information to other companies with your
          consent or as part of a specific program or feature provided in
          partnership with a third party. If you choose to participate in such
          program or feature, you authorize those third parties to use your
          personal information. For example, we offer coupons on or through our
          Services that require you to fill out an advertiser survey in order to
          receive the coupon. If you choose to participate in the survey, then
          you authorize us to disclose the information you provide to the
          advertiser who provided the survey. You may also be given the option
          to sign up for promotional emails or text messages from a third party.
          If you sign up for such promotional emails or text messages, you
          authorize us to provide your email address or phone number to the
          applicable third party. In addition, we may disclose your personal
          information to third parties to obtain associated information,
          including cookies and other identifiers that do not personally
          identify you, and for marketing on our behalf. In such cases, the
          third party would be restricted from using your personal information
          for other purposes not associated with our Services.
        </p>
        <p>
          (e) We may disclose cookies or other identifiers (such as a
          cryptographic hash of an email address, certain cookies, age, gender,
          household income, interests, zip code, state, coupon activation and
          redemption data, purchase data, and other automatically collected
          information) and inferences drawn from information we collect to third
          parties for advertising and promotional use. A third party may match
          such information with information you have previously provided to that
          party, or other information that party has obtained. We also may
          disclose cookies or other identifiers to third parties that use
          cookies or other similar technologies on our Sites so that they can
          tailor advertisements to your apparent interests and deliver those
          advertisements to you while you are either using our Services or on
          third-party websites. For example, if you print pet food coupons, then
          we may conclude that you have a pet and show you a pet care supply
          advertisement instead of a random advertisement.
        </p>
        <p>
          (f) We may aggregate personal information (excluding personal
          information that specifically identifies an individual, or that can be
          used to contact or locate a specific individual) or de-identify
          personal information and disclose such aggregated or de-identified
          information to third parties; we may also disclose such aggregated or
          de-identified information publicly to provide information about
          general use of our Services. In addition, we may permit our
          third-party service providers and vendors that collect personal
          information in connection with performing functions or providing
          Services on our behalf to aggregate that personal information
          (excluding personal information that specifically identifies an
          individual, or that can be used to contact or locate a specific
          individual) or de-identify that personal information, to improve their
          own products and services or for their internal business and research
          purposes and we may permit such third parties to disclose such
          aggregated or de-identified information publicly to provide
          information about their services.
        </p>
        <p>
          (g) In the event that we are acquired by or merged with a third-party
          entity, we reserve the right, to transfer or assign the information we
          have collected from you as part of such merger, acquisition, sale, or
          other change of control. You will be notified by email or a prominent
          notice on our Services of any change in ownership or uses of your
          personal information, as well as any choices you may have regarding
          your personal information.
        </p>
        <p>
          (h) We may disclose information about you if required to do so by law
          or in the good-faith belief that such action is necessary to comply
          with local, state, or federal laws or to respond to a court order,
          judicial or other governmental subpoena or warrant, or in the event of
          bankruptcy proceedings. For example, where required by law, we will
          publicly disclose information about the winners of sweepstakes and
          contests. We also reserve the right to disclose information when we
          believe, in good faith, it is appropriate or necessary to protect us
          or the third parties we work with from fraudulent, abusive, or
          unlawful uses, investigate and defend ourselves against any
          third-party claims or allegations, assist government enforcement
          agencies, protect the security or integrity of the Services, and to
          protect our rights, property, or safety and those of the third parties
          we work with.
        </p>
        <h6>6. Your Choices</h6>
        <p>
          Site Accounts. If you registered a password-protected account with us,
          you may modify or correct your personal information and preferences at
          any time by logging into this account or by contacting us. We will
          respond to you as soon as practicable and within 45 days from the date
          you contact us. If there is personal information that we cannot fully
          modify or delete upon your request, we will provide you with any
          available options. Before we are able to modify or correct your
          personal information, you may be required to provide additional
          authentication to confirm your identity, which may include, but is not
          limited to, email verification or a text message.
        </p>
        <p>
          Emails. You can opt-out of receiving marketing coupon email alerts and
          other emails such as newsletters by following the instructions
          included in our emails to you, or by contacting us. In addition, you
          can change your preferences for certain bigsaveplus.com emails by
          logging into your bigsaveplus.com account and going to "Email
          Settings" or "Alerts." Please note that if you opt out from all
          promotional emails, we may still send you transactional or
          administrative messages.
        </p>
        <p>
          In-App Push Advertising. Please note that if you opt out from all
          promotional push messages, we may still send you transactional or
          administrative messages through the App.
        </p>
        <p>
          California Do Not Track Disclosure. Because at this time, there is no
          general agreement on how companies should interpret Do Not Track
          signals, we do not currently respond to Do Not Track signals, whether
          a signal is received on a computer or on a mobile device. However, we
          offer you choices about how we use the information we collect through
          online tracking.
        </p>
        <p>
          Privacy Contact. If you have any other questions about this policy or
          privacy related issues, please send an email to
          privacy@bigsaveplus.com.
        </p>
        <h6>7. Your Rights</h6>
        <p>
          Depending on your state of residence, you may have certain rights with
          respect to your information under applicable law. This section
          describes the rights that may be available to you.
        </p>
        <h6>a. Information Rights</h6>
        <p>
          You may have the right to make certain requests regarding your
          "personal information" or "personal data" (as such terms are defined
          under applicable law, and collectively referred to herein as "personal
          information"). Specifically, you may have the right to ask us to:
        </p>
        <p>
          Inform you about the categories of personal information we collect or
          disclose about you; the categories of sources of such information; the
          business or commercial purpose for collecting your personal
          information; and the categories of third parties with whom we disclose
          personal information.
        </p>
        <p>
          Provide you access to and/or a copy of certain personal information we
          hold about you.
        </p>
        <p>Correct or update personal information we hold about you.</p>
        <p>Delete certain personal information we have about you.</p>
        <p>
          You may also have certain rights to opt out rights regarding the
          collection and use of your personal information, those rights are
          described below in subsection b.
        </p>
        <p>
          Depending on applicable law, you may have the right to appeal our
          decision to deny your request, if applicable.  If we deny your
          request, we will provide you with information on how to appeal the
          decision, if applicable, in our communications with you.
        </p>
        <h6>b.Opting Out of Sales and Processing for Targeted Advertising</h6>
        <p>
          As explained above, we provide information about your device and
          online browsing activities to third-party advertising partners for
          targeted advertising purposes. The disclosure of your information to
          these third parties may be considered a "sale" of personal information
          under applicable law or the processing of personal information for
          targeted advertising purposes.
        </p>
        <p>
          Please note that Big Save Plus Venture does not use personal
          information to make decisions that produce legal or similarly
          significant effects, and thus we are not required to have an opt-out
          for such uses.
        </p>
        <p>
          Please note that if you have a legally-recognized browser-based opt
          out preference signal turned on via your device browser, we recognize
          such preference in accordance with applicable law.
        </p>
        <h6>
          8. Registration via Third-Party Accounts and Social Media Sharing
        </h6>
        <p>
          You may be given the opportunity to register with us via third-party
          accounts such as Facebook, Apple, or Google. These services will
          authenticate your identity and may provide you the option to provide
          certain information (such as, but not limited to, your name and email
          address) to us. We may store the information you provide us through
          such accounts and combine it with other information, including
          personal information. Disabling your third-party account may not
          terminate your account with us.  Refer to the terms and service and
          privacy policy providing the third-party account for more information
          on how they disclose information when you use your account to register
          with services such as Ours.
        </p>
        <p>
          Our Services may also include social media features, such as sharing
          widgets provided by Facebook, Twitter, Google, Pinterest and others.
          These features may collect your IP address, which page you are
          visiting on our site, and other information, and may set a cookie to
          enable the feature to function properly. Social media features and
          widgets are either hosted by a third party or hosted directly on our
          Services. Your interactions with these features are governed by the
          terms of service and privacy policy of the company providing them.
        </p>
        <h6>9. Community Forums/Blogs; User-Generated Content</h6>
        <p>
          We may offer features that allow users to post comments, reviews,
          recipes, photos, video and other user-generated content to public
          areas within the Services ("Community Forums"). By default, when you
          post content to a Community Forum, it is public. In addition, your
          account name and photo may be associated with the content, and if so,
          may be displayed publicly. Your user profile may also link to your
          public profile from a third-party account if you registered with us
          through Facebook or other such services. Please take special note that
          your user profile and any user-generated content that you post,
          including any personal information, are intended for public
          consumption, will be publicly accessible and will not be considered
          confidential. We may display user-generated content on our Services,
          including comments and recipes, and we or our users may provide such
          content to third-party sites and services. To request removal of your
          personal information from any Community Forum, please contact us. In
          some cases, we may not be able to remove your personal information, in
          which case we will let you know if we are unable to do so and why.
        </p>
        <h6>10. Our Commitment to Data Security</h6>
        <p>
          The Services may use various physical, electronic, and administrative
          procedures to safeguard the confidentiality of your personal
          information. Additional security measures, such as Secure Sockets
          Layer ("SSL") or other technology, will be used if you engage in
          financial transactions on our Services. However, as no security
          measures are perfect or impenetrable, we cannot guarantee the security
          of any information you transmit to us or that unauthorized access or
          theft of data might not occur.
        </p>
        <p>
          For features on our Services that require a login or password, it is
          important for you to protect against unauthorized access to your
          password and your computer or mobile device. Use strong logins and
          passwords that are difficult to guess and change your logins and
          password periodically. Be sure to sign off when finished using a
          shared computer. Please notify us immediately if you think your
          account or login or password has been compromised.
        </p>
        <h6>11. Our Commitment To Children’s Privacy</h6>
        <p>
          The Site is not designed for use by anyone under the age of 16, and
          any use or access of the Site by anyone under the age of 16 is
          strictly prohibited. If we learn that personal information of a user
          under the age of 16 has been collected through the Services, then we
          will take the appropriate steps to delete this information.
        </p>
        <h6>
          12. Special Notice to Users Outside The United States of America
          Regarding International Transfers
        </h6>
        <p>
          The Internet is a global environment and BigSavePlus Ventures is an
          international business with headquarters in the United States of
          America ("U.S.) and so the personal information it collects about you
          may be processed in any of the countries where REV, its service
          providers or business partners operate, including the U.S.  The data
          protection laws of these countries may be different to the laws of the
          country where you are resident, but BigSavePlus Ventures will protect
          your personal information strictly in accordance with this Privacy
          Policy at all times, wherever it is processed. Therefore, by using our
          Services and communicating electronically with us, you acknowledge and
          agree to our processing of your information in this way.
        </p>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
