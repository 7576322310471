import axios from 'axios'
import { useEffect, useState } from 'react'

const TestOffer = () => {
	const [couponDetails, setCouponDetail] = useState(null)
	useEffect(() => {
		axios
			.get('https://apiz.adam-app.xyz/v1/coupons/911')
			.then((response) => {
				// Handle the successful response here
				setCouponDetail(response.data?.data)
			})
			.catch((error) => {
				// Handle any errors here
			})
	}, [])
	return couponDetails ? (
		<div style={{ marginTop: '200px' }}>
			<p>{couponDetails?.offer}</p>
			<p>{couponDetails?.product_name}</p>
		</div>
	) : (
		<p>Loading...</p>
	)
}

export default TestOffer
