import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavigationBar = () => {
  return (
    <Navbar
      style={{ width: "100vw" }}
      expand="sm"
      bg="light"
      variant="light"
      className="flex justify-content-between px-4 lg:px-0"
    >
      <div>
        <Link
          to=""
          style={{
            textDecoration: "none",
            color: "black",
          }}
        >
          <Navbar.Brand href="#" className="d-flex flex-column fw-bold">
            <span> BigSavePlus</span>
            <span className="text-center" style={{ fontSize: "0.5em" }}>
              The Ultimate Offers Site
            </span>
          </Navbar.Brand>
        </Link>
      </div>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="flex justify-end fw-semibold">
            <Nav.Link href="/#home">Home</Nav.Link>
            <Nav.Link href="/#hot">Hot Coupons</Nav.Link>
            <Nav.Link href="/#new">New Coupons</Nav.Link>
            <Nav.Link href="/#deal">Deal of the Day</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavigationBar;
