import React, { useEffect, useState } from "react";
import Card from "./Card";
import { background } from "./common";
// import { hot } from "./companies";
import { Link } from "react-router-dom";
// import { getAllCoupons } from "../service/coupon.service";
// import { Axios } from '../service/Axios'
// import axios from "axios";
// import { useAuth } from "../context/AuthContext";
import { useCoupon } from "../context/couponContext";

const Home = () => {
  const { coupons } = useCoupon();

  return (
    <section id="home" className="py-5" style={{ background: background.home }}>
      <div
        className="fw-bold fs-2 d-flex row justify-content-center py-4"
        style={{ paddingTop: "10px" }}
      >
        Home
      </div>
      <center>
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-3 ">
            {coupons.slice(0, 6).map((item) => {
              const image = require("../companies/" + item.image);
              return (
                <div className="col">
                  <Link
                    to={`/offer/${item.name}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card image={image} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </center>
    </section>
  );
};

export default Home;
// {hot.slice(0, 6).map((item) => {
//   const image = require("../companies/" + item.img);
//   return (
//     <div className="col">
//       <Link
//         to={`/offer/${item.name}`}
//         style={{ textDecoration: "none" }}
//       >
//         <Card image={image} />
//       </Link>
//     </div>
//   );
// })}
