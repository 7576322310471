import SimpleCrypto from "simple-crypto-js";

const SECRET_KEY = process.env.REACT_APP_COUPON_ENVCRYPT_KEY;
const simpleCrypto = new SimpleCrypto(SECRET_KEY);

const decryptText = (encryptedText) => {
  try {
    return simpleCrypto.decrypt(encryptedText);
  } catch (error) {
    // Handle decryption error here
    console.error("Decryption error:", error);
    return null;
  }
};

export default decryptText;
