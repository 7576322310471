import React from "react";
import { Container } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <Container>
      <div className="p-5">
        <div className="p-5 fw-bold text-center">Terms & Conditions</div>
        <p>
          By continuing to use our Services on or after January 1, 2023, you are
          agreeing to the updated Terms of Use below.
        </p>
        <h6>Effective from January 1, 2023</h6>
        <p>
          These Terms of Use ("Terms") apply to all websites, services,
          software, and mobile applications provided by BigSavePlus. and its
          subsidiaries (together, "BigSavePlus," "we" or "our"), including the
          website www.bigsaveplus.com and designated portions of third party
          websites and applications that link to or refer to these Terms
          (collectively, the "Services").
        </p>
        <p>
          PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICES. THESE
          TERMS INCLUDE A BINDING ARBITRATION PROVISION THAT INCLUDES A JURY
          TRIAL WAIVER AND CLASS ACTION WAIVER, AND A CLAUSE THAT GOVERNS THE
          JURISDICTION AND VENUE FOR ANY DISPUTES.
        </p>
        <p>
          BY USING THE SERVICES, YOU ARE AGREEING TO THESE TERMS. IF YOU DO NOT
          AGREE TO THESE TERMS, YOU MAY NOT USE THE SERVICES.
        </p>
        <p>
          BigSavePlus RESERVES THE RIGHT, AT ITS SOLE DISCRETION, TO CHANGE,
          MODIFY, ADD OR REMOVE PORTIONS OF THESE TERMS, AT ANY TIME, BY POSTING
          CHANGES TO THIS PAGE. YOUR CONTINUED USE OF THE SERVICES FOLLOWING THE
          POSTING OF CHANGES MEANS THAT YOU ACCEPT AND AGREE TO ALL SUCH
          CHANGES.
        </p>
        <h6>1. PRIVACY POLICY AND ADDITIONAL TERMS</h6>
        <p>
          Our Privacy Policy explains how we collect, use and share information,
          and is hereby incorporated into these Terms. You agree that your use
          of the Services is governed by our Privacy Policy.
        </p>
        <p>
          Certain Services may be subject to additional terms, which will be
          posted with such Services, such as terms governing a referral program.
          If you choose to use such Services, you agree that the additional
          terms apply.
        </p>
        <h6>2. ELIGIBILITY</h6>
        <p>
          To use the Services, you must be, and hereby represent that you are,
          an individual 16 years or older who can form legally binding
          contracts. Any use of the Services by anyone under the age of 16 is
          strictly prohibited. If you are a minor in your jurisdiction of
          residence, you must have the consent of your parent or legal guardian
          to enter the Terms and use the Service. If you have BigSavePlusiously
          been suspended from the Services, or if we have pBigSavePlusiously
          suspended or deactivated your User Account (defined below), you are
          prohibited from use of the Services. Users must be human; no machines,
          scripts, or automated services may be used to accumulate any financial
          benefits derived from the use of the Service.
        </p>
        <h6>3. ACCOUNTS AND AUTHORISED BROWSERS OR DEVICES</h6>
        <p>
          To use certain Services, you may be required to create an account and
          associated password or authorise a browser or device through SMS
          verification or other means ("User Account"). You agree to: (a)
          provide true, accurate, current and complete information about
          yourself as prompted by the Services, and (b) maintain and promptly
          update information about yourself to keep it true, accurate, current
          and complete. You further agree that you will not create a User
          Account using any email address, phone number, or other personally
          identifying information that is not yours.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          password(s) and User Account information, and you are responsible for
          all activities that occur using your password or User Account or as a
          result of your use or access to the Services. You agree that
          BigSavePlus will not be liable for any loss that you may incur as a
          result of someone else using your password or User Account, either
          with or without your knowledge. You may use only one User Account. Any
          duplicate User Accounts will be subject to cancellation. You may not
          use anyone elses User Account at any time.
        </p>
        <h6>4. RETAILER ACCOUNT LINKING</h6>
        <p>
          If you link your User Account to a retailer loyalty card program or to
          a retailer online account (each a "Linked Account"), you are agreeing
          to share, and authorising us to use, your loyalty card program ID or
          account information to collect information about your shopping
          activity in-store and/or online either directly from the retailer or
          by using your credentials to access your retailer online account and
          for other purposes as described in our Privacy Policy. This
          information may include historical (up to two years, if available) and
          future purchase data, including products purchased, quantities
          purchased, prices charged, where you made the purchases (for example,
          store name, store location, website address), and the time and date of
          your purchases. We do not use your loyalty ID number or online account
          information to collect data about you that is not normally provided on
          a purchase receipt, such as payment card account information (account
          number, expiration date, security code, or billing address), bank
          account numbers, prescription histories, or medical information. All
          such collected information is subject to our Privacy Policy. For
          purchases made after you link a Linked Account, we will BigSavePlus
          review the purchase data associated with your Linked Account and
          automatically identify qualifying purchases that match any activated
          Offers associated with your User Account and apply coupons or Rewards,
          as appropriate. When making purchases, you should follow the
          respective retailer instructions to ensure your purchases are
          associated with your Linked Account. For example, you should enter
          your loyalty card number or the phone number associated with your
          Linked Account at checkout in-store, use a payment method associated
          with your Linked Account that will register your in-store purchases
          with your Linked Account, or ensure you are logged in to your retailer
          online account when making purchases. If your purchases do not appear
          in your Linked Account purchase history, you will not be credited for
          such purchases at a retailer for which you have a Linked Account.
        </p>
        <h6>5. OWNERSHIP OF CONTENT</h6>
        <p>
          Except for User Content (defined below), all coupons, offers, data,
          text, images, logos, photographs, advertisements, graphics, press
          releases, audio, video, documents, and other information and content
          available on or through the Services ("Content"), is the property of
          BigSavePlus or its licensors. The Content is protected by copyright,
          trademark, and/or other intellectual property laws and you acknowledge
          and agree that we retain all right, title and interest in and to the
          Content.
        </p>
        <p>
          Except as expressly stated in these Terms, you may not sell, transfer,
          alter, reproduce, distribute, republish, download, display, post, or
          transmit any Content, in whole or in part, by any means.
        </p>
        <h6>6. PROMOTIONS</h6>
        <p>
          You agree to comply with the terms and conditions associated with any
          offers, coupons, coupon codes, and other promotions available on or
          through the Services.
        </p>
        <p>You may only redeem each coupon once.</p>
        <p>
          If you print coupons using any of our print technologies, you agree
          that we may install or store certain authentication and print control
          technologies on your devices. You agree not to interfere with or
          circumvent such technologies.
        </p>
        <p>
          You agree to comply with campaign and user limits and only print or
          activate coupons for your own personal and non-commercial use.
        </p>
        <p>
          You agree not to sell, barter or transfer any coupon, or any
          electronic or hard copy of a coupon, to any third party.
        </p>
        <h6>7. CASH BACK OFFERS</h6>
        <p>
          The following terms apply to all offers where you are eligible to earn
          cash back, rebate, or other rewards specified by the advertiser,
          excluding discounts or other price reductions taken at the time of
          sale, (each, a "Reward") by submitting in-store receipts or by using a
          Linked Account to provide purchase transaction information to
          BigSavePlus (all herein "Cash Back Offers"):
        </p>
        <p>
          You must link a payment account (e.g. PayPal account) to your User
          Account in order to earn Rewards from Cash Back Offers. IF, WITHIN 3
          MONTHS OF SUBMITTING A REDEMPTION REQUEST, YOU DO NOT VERIFY YOUR
          PAYMENT ACCOUNT AND VERIFY YOUR BROWSER OR DEVICE THROUGH SMS
          VERIFICATION OR OTHER MEANS, YOU ARE NOT ELIGIBLE TO EARN ANY REWARDS,
          AND ALL REWARDS SHALL REMAIN THE SOLE PROPERTY OF BigSavePlus.
        </p>
        <p>
          You agree that any information you provide by submitting in-store
          receipts or via a Linked Account is subject to the terms of
          BigSavePluss Privacy Policy.
        </p>
        <p>
          If you are redeeming a Reward for any Cash Back Offer that was
          initiated by i) texting a keyword from a phone or device to the short
          code 76666 or ii) providing your phone number to receive a Cash Back
          Offer via a text message, you further agree to the receive phone
          correspondence as well.
        </p>
        <p>
          Some Cash Back Offers are store-specific and require that the product
          be purchased from a particular store(s) identified in the details of
          the offer.
        </p>
        <p>
          To earn Rewards by submitting a receipt, the purchase must be made at
          a store whose receipt, e-receipt, or computerized receipt provides at
          least the following information: store name, location, date and time
          of purchase, total amount of receipt, and, for the product(s) that
          relate to the offer, the quantity, price paid, and product name and
          description.
        </p>
        <p>
          To earn Rewards by using a Linked Account, you must first link your
          online retailer or loyalty card account, if available, to your User
          Account. After linking your Linked Account, the qualifying purchase
          must be made using your Linked Account Information so that the
          purchases are listed in your Linked Account purchase history or
          otherwise associated with your Linked Account (e.g. by providing your
          loyalty card ID, phone number, or an associated payment method at
          checkout in-store, or by logging into your account when making online
          purchases). Rewards on purchases made prior to linking a Linked
          Account cannot be redeemed using a Linked Account. To redeem Rewards
          for purchases made prior to linking your Linked Account, Rewards
          should be redeemed by submitting a copy of the receipt before
          attempting to link a retailer loyalty card program or retailer online
          account to your User Account. After requesting to link a retailer
          loyalty account or retailer online account to your User Account,
          receipt submissions can no longer be made for that retailer.
        </p>
        <p>
          Cash Back Offers may not be combined with other manufacturer coupons
          or rebates for the same product in the same purchase. We may reject
          any Reward requests for products purchased using other manufacturer
          coupons or rebates. We may limit access to Cash Back Offers to a
          single redemption, such that when a Cash Back Offer is used at one
          retailer, the Cash Back Offer cannot be used again at any retailer.
        </p>
        <p>
          Any receipts you submit must be for your own purchases, and you may
          not modify the receipt you receive from the applicable retailer in any
          way. The entire receipt must be submitted in order to earn Rewards.
          You may not submit any purchase receipt that has already been uploaded
          by you or another user in an attempt to request multiple Rewards for
          the same purchase or any products you did not actually purchase.
        </p>
        <p>
          Unless otherwise stated in the offer terms, you must "clip," or
          activate, the Cash Back Offer from your User Account on our website or
          mobile app prior to purchase, and, if requesting a Reward by
          submitting your purchase receipt, submit your purchase receipt within
          seven (7) days of the expiration of the Cash Back Offer.
        </p>
        <p>
          You must comply with all applicable offer terms to be eligible for a
          Reward. We have sole discretion to determine whether you have complied
          with all applicable offer terms, including the Terms herein and any
          terms provided with a specific Cash Back Offer, and are eligible for a
          Reward. We reserve the right to request additional information in
          order to verify the identity of the purchaser or the validity of the
          proof of purchase (including, without limitation, a copy of the
          packaging of the product along with barcode and/or the original
          receipt).
        </p>
        <p>
          Once we have determined that you are eligible for a Reward, we will
          distribute the Reward to you through your verified PayPal account or
          other payment options as we may make available from time to time. We
          may use Hyperwallet payment services to deliver the Reward to you, and
          such payment services are subject to the Hyperwallet Terms of Service
          and the Hyperwallet Privacy Policy. We reserve the right to modify the
          available means for distributing Rewards.
        </p>
        <p>
          You must not use a payment account associated with another User
          Account. A single payment account cannot be connected to multiple User
          Accounts.
        </p>
        <p>
          You are solely responsible for any tax liability arising from your
          receipt of Rewards.
        </p>
        <p>
          You may not submit a purchase receipt for a product that you have
          returned, or intend to return, to the applicable retailer. If you
          return or exchange any products for which you received a Reward, you
          must notify BigSavePlus via email at support@bigsaveplus.com within
          twenty-four (24) hours after the return or exchange. In such an event,
          or in the event that we discover a return or exchange of which we were
          not notified, we may, in our sole discretion, (a) request a return of
          the value of the relevant Reward; (b) terminate the User Account; or
          (c) both.
        </p>
        <p>
          If you attempt, as determined by BigSavePlus at its sole discretion,
          to redeem Rewards (i) attributable to purchases that are not your own
          (for example, use your Customer ID in connection with purchases by
          non-family members), (ii) multiple times for a single purchase, (iii)
          by engaging in any Prohibited Conduct (see Prohibited Conduct section
          below) or (iv) by engaging in other abusive or fraudulent behavior, we
          may, at our sole discretion, (a) recover any such losses directly from
          you; or (b) withdraw, and you expressly authorize BigSavePlus to
          withdraw, any such Rewards from your User Account, including any third
          party accounts to which the Rewards were transferred or otherwise
          credited. Additionally, we may terminate your User Account.
        </p>
        <h6>8. PROHIBITED CONDUCT</h6>
        <p>
          In addition to the other restrictions explicitly set forth in these
          Terms, you agree not to:
        </p>
        <p>
          use any software, script, code, device, crawler, robot or other means
          not provided by us to access the Services, any BigSavePlus system, or
          Content, including coupons;
        </p>
        <p>
          circumvent, disable or otherwise interfere with security-related
          features on the Services;
        </p>
        <p>
          access or use the Services in any manner that may damage, disable,
          unduly burden, or impair any BigSavePlus networks or systems;
        </p>
        <p>
          create multiple User Accounts to circumvent any limitations on offers
          provided by the Services;
        </p>
        <p>
          engage in any fraudulent, deceptive or otherwise improper actions when
          using the Services;
        </p>
        <p>
          gain or attempt to gain unauthorized access to any areas of the
          Services, or any BigSavePlus networks or systems, or to interfere or
          attempt to interfere with any BigSavePlus networks or systems;
        </p>
        <p>
          interfere or attempt to interfere with Services provided to any user,
          host or network, including, without limitation, via means of
          submitting a virus to the Services, spamming, crashing, or otherwise;
        </p>
        <p>
          engage in commercial use or distribution of any Services or the
          Content (including any coupons, offers, rebates, promotions), or copy
          or create any derivative work of any Services or Content; and
        </p>
        <p>
          use your User Account or the Services for the benefit of any third
          party.
        </p>
        <h6>9. USE OF THE SERVICES</h6>
        <p>
          Subject to your compliance with these Terms, BigSavePlus grants you a
          limited, non-exclusive, non-transferable, non-sublicensable license to
          access and make personal and non-commercial use of the Services. All
          rights not expressly granted to you in these Terms are reserved and
          retained by BigSavePlus or its licensors, suppliers, publishers,
          rights-holders, or other content providers. The licenses granted by
          BigSavePlus herein terminate if you do not comply with these Terms.
        </p>
        <h6>10. USE OF SOFTWARE</h6>
        <p>
          Your use of any software provided by BigSavePlus ("Software") is
          subject to these Terms and all agreements including all license
          agreements and end user agreements that accompany or are included with
          the Software, and other terms and conditions that apply (collectively,
          "EULA Terms"). In the event that Software is provided on or through
          the Services or any other authorized digital distribution platform,
          and is not licensed for your use through any EULA Terms, we hereby
          grant you a personal, non-sublicensable, non-transferable, BigSavePlus
          applicable-at-will licence in the United States to use the Software,
          which license is conditioned upon your continuing compliance with
          these Terms, including all of the following: (a) the Software may be
          used solely for your personal and noncommercial purposes; (b) you may
          not attempt to, or authorize any third party to, decompile,
          BigSavePluserse engineer or otherwise attempt to gain access to the
          Software source code (except to the extent permitted by law
          notwithstanding this restriction); (c) you may not attempt to, or
          authorize any third party to, disable or circumvent the intended
          operation of the Software, including but not limited to any
          authentication and print control technologies, or disclose any such
          method or means to any third party; (d) you may not modify, alter, or
          create derivative works of the Software; and (e) you may not transfer,
          sublicense, assign, copy or redistribute any Software.
        </p>
        <h6>11. SERVICE AVAILABILITY</h6>
        <p>
          BigSavePlus may change the Services at any time without notice.
          BigSavePlus makes no representation that the Services will be
          available for use in your location.
        </p>
        <h6>12. USER CONTENT</h6>
        <p>
          BigSavePlus may permit registered users to post content on or through
          the Services, including comments, recipes, BigSavePlusiews, photos,
          videos, and other materials ("User Content"). You understand and agree
          that BigSavePlus does not BigSavePlusiew User Content prior to posting
          and is not responsible for the accuracy, usefulness, safety, or
          intellectual property rights of or relating to User Content. You
          acknowledge that you may see User Content on the Services that you
          find inaccurate, offensive, indecent, or objectionable; and you agree
          to, and hereby do, waive any legal or equitable claims, rights, or
          remedies you may have against BigSavePlus arising from or relating to
          User Content.
        </p>
        <p>
          If you choose to post User Content, please be aware that your User
          Content and your public account profile (e.g., your name and any photo
          submitted through the registration process) will be available to the
          public. If you register with us via a third party account such as
          Facebook or Google, your BigSavePlus profile may link to your public
          profile at that account as well. You should therefore only post User
          Content you are comfortable sharing under these Terms.
        </p>
        <p>
          You are solely responsible for your User Content and the consequences
          of posting your User Content to the Services. You represent and
          warrant that you own or have the necessary licenses, rights, consents,
          permissions, and releases to publish or perform User Content you post
          or submit and to grant BigSavePlus the licenses and rights set forth
          in these Terms.
        </p>
        <p>
          You retain all rights in and to the User Content you post or submit to
          the Services. However, by posting or submitting User Content, you
          hereby grant BigSavePlus a perpetual, nonexclusive, royalty-free,
          transferable, sublicensable, is BigSavePlus applicable, worldwide
          right and license to reproduce, prepare derivative works of,
          distribute, publicly perform, publicly display, adapt, broadcast,
          communicate and otherwise use your User Content (including any
          copyright, trademark, publicity, or other proprietary rights therein
          and thereto) in whole or in part, for any purpose, including without
          limitation in connection with the Services and BigSavePluss business,
          marketing or promotional purposes, and/or for syndicating User
          Content, promoting or redistributing part or all of the Services (and
          derivative works thereof) in any website, online application, mobile
          application, social media channel, or other medium now known or later
          developed. You also hereby grant BigSavePlus a perpetual,
          nonexclusive, royalty-free, transferable, sublicensable, applicable
          worldwide right and license to use your name, likeness, photograph,
          image, voice, content and statements in connection with your User
          Content and any derivative works thereof, including to promote or
          advertise the Services or the products and services of affiliates,
          advertisers and other third parties. This means, for example, that you
          permit a business or other entity to pay us to display your name
          and/or picture with your content or information, without any
          compensation to you. To the extent permitted by law, you hereby waive
          any moral rights you may have in or to your User Content. You agree
          that you are not entitled to any compensation if your User Content,
          including without limitation, your name, likeness, photograph or image
          is adjacent to or included with advertisements, promotions, coupons,
          offers or other information, or otherwise used to promote the products
          and Services of BigSavePlus or any other company or product that is
          addressed in your User Content.
        </p>
        <p>
          You agree not to post any User Content that (each, a "Prohibited
          Content"):
        </p>
        <ul>
          <li>
            Violates a third party's copyrights, trade secrets, trademarks,
            privacy rights, publicity rights, or other intellectual or
            proprietary rights;
          </li>
          <li>
            You do not have the right to disclose under any law, contractual
            obligation, or fiduciary relationship;
          </li>
          <li>
            Violates, or encourages conduct that violates, laws or regulations;
          </li>
          <li>Is fraudulent, false, misleading, or deceptive;</li>
          <li>
            Is sexually explicit, pornographic, obscene, defamatory, libelous,
            threatening, harassing, hateful, discriminatory, racially or
            ethnically offensive, abusive, violent, humiliating to or bullying
            of other people, or otherwise inappropriate, as deemed by
            BigSavePlus in its sole discretion;
          </li>
          <li>
            May create a risk of harm, loss, emotional distress, or physical or
            mental injury to yourself, any other person, or any animal;
          </li>
          <li>
            Is harmful to or exploitative of children, or that includes images
            or videos of children without first obtaining the consent of their
            parent or guardian;
          </li>
          <li>
            Contains advertisements or solicitation of business (except with our
            prior express written approval);
          </li>
          <li>
            Is or sends spam, surveys, unsolicited advertising or promotional
            materials, or chain letters;
          </li>
          <li>
            Contains or links to any material that contains software viruses,
            corrupted files, or any other similar software, files, or programs
            that may damage or adversely affect the operation of the Services,
            the Services, or the computer or systems of another user;
          </li>
          <li>Impersonates another person or entity;</li>
        </ul>
        <p>
          BigSavePlus reserves the right to remove any User Content without
          notice and for any reason or no reason.
        </p>
        <h6>13. FEEDBACK</h6>
        <p>
          All feedback, comments and suggestions for improvements to the
          Services, or new Services (collectively, "Feedback"), that you submit
          to BigSavePlus shall be the sole and exclusive property of BigSavePlus
          without any compensation or attribution to you. You hereby
          irBigSavePlusocably assign, and agree to irBigSavePlusocably assign,
          to BigSavePlus all your right, title, and interest in and to all
          Feedback, including without limitation all worldwide patent,
          copyright, trade secret or other proprietary rights therein, and to
          the extent permitted by law waive any moral rights you may have in
          such Feedback. You agree to execute documents and take such further
          acts as BigSavePlus may reasonably request of you to assist
          BigSavePlus to acquire, perfect, maintain, and assert its intellectual
          property and other legal rights in the Feedback.
        </p>
        <h6>14. ELECTRONIC COMMUNICATIONS</h6>
        <p>
          By creating a User Account, you consent to receiving certain
          electronic communications from us at the email address you provided as
          further described in our Privacy Policy. Your consent to receive
          electronic communications includes consent to receive any notices or
          other information that we may be required by law to provide you in
          writing or otherwise. You agree to keep your User Account information
          current, including your current email address should the same change.
          We may also send you push notifications if you install the mobile
          application.
        </p>
        <h6>15. DISCLAIMERS</h6>
        <p>
          All Content, including without limitation, any advice, recommendation
          or opinion, offer or coupon, or information about grocery or other
          product, service, retailer, recipe, drug, and health is provided on or
          through the Services for informational purposes only, and should not
          be construed to indicate that any such Content is endorsed by
          BigSavePlus, nor is there any representation or warranty by
          BigSavePlus that the Content is reliable, accurate, timely, complete,
          effective, or safe for your use. Always consult your medical
          professional regarding any health or medical condition and before
          using any medical product or any over-the-counter drug. YOU
          ACKNOWLEDGE THAT RELIANCE ON ANY INFORMATION PROVIDED ON OR THROUGH
          THE SERVICES IS SOLELY AT YOUR OWN RISK.
        </p>
        <p>
          THE SERVICES AND ALL CONTENT ON OR AVAILABLE THROUGH THE SERVICES ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. BigSavePlus EXPRESSLY
          DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
          FURTHER, BigSavePlus MAKES NO WARRANTY THAT: (A) THE SERVICES, WILL
          MEET YOUR REQUIREMENTS; (B) THE SERVICES WILL BE AVAILABLE ON AN
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (C) THE RESULTS
          THAT MAY BE OBTAINED FROM USE OF THE SERVICES WILL BE ACCURATE,
          TIMELY, OR RELIABLE; OR (D) THE QUALITY OF THE SERVICES WILL MEET YOUR
          EXPECTATIONS. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE
          SERVICES AND ANY THIRD PARTY SITE. BigSavePlus SHALL HAVE NO
          RESPONSIBILITY OR LIABILITY FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
          LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE SERVICES OR ANY THIRD
          PARTY SITE.
        </p>
        <p>
          ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
          SERVICES OR SOFTWARE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND
          YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS
          AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER
          SYSTEM, INTERNET ACCESS, DOWNLOAD OR DISPLAY DEVICE, OR LOSS OF DATA
          THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM BigSavePlus OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY
          WARRANTY NOT EXPRESSLY STATED IN THE TERMS. BigSavePlus SHALL NOT BE
          RESPONSIBLE OR LIABLE FOR THE ACCURACY, USEFULNESS OR AVAILABILITY OF
          ANY INFORMATION TRANSMITTED OR MADE AVAILABLE THROUGH THE SERVICES,
          AND SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY MEDICAL, TRADING,
          INVESTMENT OR OTHER DECISIONS BASED ON SUCH INFORMATION.
        </p>
        <p>
          IF THE JURISDICTION YOU ARE IN DOES NOT ALLOW FOR THE EXCLUSION OF
          CERTAIN WARRANTIES, THEN SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY
          TO YOU IN CERTAIN CIRCUMSTANCES.
        </p>
        <h6>16. LIMITATION OF LIABILITY</h6>
        <p>
          IN NO EVENT SHALL BigSavePlus AND ITS LICENSORS BE LIABLE FOR ANY
          DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, OR
          DAMAGES FOR LOSS OF PROFITS, BigSavePlusENUE, DATA OR USE, INCURRED BY
          YOU OR ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT,
          ARISING FROM YOUR ACCESS TO, OR USE OF, OR INABILITY TO USE THE
          SERVICES OR ANY CONTENT PROVIDED ON OR THROUGH THE SERVICES, EVEN IF
          BigSavePlus HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR
          LOSSES. NOTWITHSTANDING THE FOREGOING, THE TOTAL LIABILITY OF
          BigSavePlus AND ITS LICENSORS, WHETHER IN CONTRACT, WARRANTY, TORT
          (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), PRODUCT LIABILITY, STRICT
          LIABILITY OR ANY OTHER THEORY, ASSOCIATED WITH ANY CLAIM ARISING OUT
          OF OR RELATING TO USE OF OR ACCESS TO THE SERVICES FOR ANY REASON
          WHATSOEVER SHALL BE LIMITED TO ONE HUNDRED DOLLARS ($100). IF THE
          JURISDICTION YOU ARE IN DOES NOT ALLOW FOR THE EXCLUSION OF CERTAIN
          TYPES OF DAMAGES, THEN SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO
          YOU IN CERTAIN CIRCUMSTANCES.
        </p>
        <h6>17. INDEMNITY</h6>
        <p>
          You agree to defend, indemnify and hold BigSavePlus and its officers,
          directors, employees, agents and affiliates harmless from any and all
          third-party claims, proceedings, damages, injuries, liabilities,
          losses, costs and expenses (including reasonable attorneys fees and
          litigation expenses), arising out of or relating to your use of the
          Services.
        </p>
        <h6>18. TERMINATION</h6>
        <p>
          BigSavePlus may, without notice to you: (a) disclose information from
          the Services to any third party, including law enforcement agencies,
          to protect its rights and property, or the rights and property of its
          clients, in response to legal process, or in a good faith belief that
          disclosure is justified or required, or in an emergency situation; (b)
          investigate any user or third party complaint, or potential violation
          of its policies; and (c) in its sole discretion and at any time,
          discontinue providing or limit your access to the Services, or any
          portion thereof.
        </p>
        <h6>19. ARBITRATION AND CLASS ACTION WAIVER</h6>
        <p>
          PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS,
          INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
        </p>
        <ul>
          <li>
            You and BigSavePlus agree that these Terms affect interstate
            commerce and that the Federal Arbitration Act governs the
            interpretation and enforcement of these arbitration provisions.
          </li>
          <li>
            This Arbitration and Class Action Waiver section is intended to be
            interpreted broadly and governs any and all disputes between us,
            including but not limited to claims arising out of or relating to
            any aspect of the relationship between us, whether based in
            contract, tort, statute, fraud, misrepresentation or any other legal
            theory; claims that arose before these Terms or any prior agreement;
            and claims that may arise after the termination of these Terms. The
            only disputes excluded from this broad prohibition are the
            litigation of certain intellectual property and small court claims,
            as provided below.
          </li>
          <li>
            Initial Dispute Resolution. We are available by email at
            support@bigsaveplus.com to discuss any concerns you may have
            regarding your use of the Services. Most concerns may be quickly
            resolved in this manner. The parties shall use their best efforts to
            settle any dispute, claim, question, or disagreement directly
            through consultation and good faith negotiations which shall be a
            precondition to either party initiating a lawsuit or arbitration.
          </li>
          <li>
            Binding Arbitration Agreement. If the parties do not reach an agreed
            upon solution within a period of thirty (30) days from the time
            informal dispute resolution is pursued pursuant to section 17A
            above, then either party may initiate binding arbitration. Unless
            you have opted out as set forth below, all claims arising out of or
            relating to these Terms (including its formation, performance and
            breach), the parties' relationship with each other and/or your use
            of the Services shall be finally settled by binding arbitration
            administered by the American Arbitration Association (the "AAA") in
            accordance with the provisions of its Commercial Consumer
            Arbitration Rules and the supplementary procedures for consumer
            related disputes of the AAA, excluding any rules or procedures
            governing or permitting class actions. The arbitrator, and not any
            federal, state or local court or agency, shall have exclusive
            authority to resolve all disputes arising out of or relating to the
            interpretation, applicability, enforceability or formation of these
            Terms, including, but not limited to any claim that all or any part
            of these Terms is void or voidable. The arbitrator shall be
            empowered to grant whatever relief would be available in a court
            under law or in equity. The arbitrator's award shall be binding on
            the parties and may be entered as a judgement in any court of
            competent jurisdiction. The procedures and rules of the Federal
            Arbitration Act shall exclusively govern the interpretation and
            enforcement of this arbitration provision.
          </li>
          <li>
            The AAA's rules governing the arbitration may be accessed at
            www.adr.org. To the extent the filing fee for the arbitration
            exceeds the cost of filing a lawsuit, we will pay the additional
            cost. A request for payment of filing fees should be submitted to
            AAA along with your form for initiating the arbitration, and we will
            make arrangements to pay all necessary filing fees directly to AAA.
            If the arbitrator finds the arbitration to be non-frivolous, we will
            pay all of the actual filing and arbitrator fees for the
            arbitration, provided your claim does not exceed $75,000. The
            arbitration rules also permit you to recover attorney's fees in
            certain cases.
          </li>
          <li>
            JURY TRIAL WAIVER. The parties understand that, absent this
            mandatory provision, they would have the right to sue in court. YOU
            AND "BigSavePlus" EACH AGREE TO WAIVE ANY RIGHT TO A JURY TRIAL FOR
            ANY ACTION AT LAW OR IN EQUITY ARISING OUT OF OR RELATING TO THESE
            TERMS OR THE SERVICES. The parties further understand that, in some
            instances, the costs of arbitration could exceed the costs of
            litigation and the right to discovery may be more limited in
            arbitration than in court.
          </li>
          <li>
            If you are a resident of the United States, arbitration may take
            place in the county where you reside at the time of filing. For
            individuals residing outside the United States, arbitration shall be
            initiated in the State of California, United States of America. You
            and BigSavePlus further agree to submit to the personal jurisdiction
            of any federal or state court in Santa Clara County, California in
            order to compel arbitration, to stay proceedings pending
            arbitration, or to confirm, modify, vacate, or enter judgement on
            the award entered by the arbitrator.
          </li>
          <li>
            Class Action Waiver. The parties further agree that any arbitration
            shall be conducted in their individual capacities only and not as a
            class action or other representative action, and the parties
            expressly waive their right to file a class action or seek relief on
            a class basis. If any court or arbitrator determines that the class
            action waiver set forth in this paragraph is void or unenforceable
            for any reason or that an arbitration can proceed on a class basis,
            then the arbitration provision set forth above shall be deemed null
            and void in its entirety and the parties shall be deemed to have not
            agreed to arbitrate disputes.
          </li>
          <li>
            30 Day Right to Opt-Out. You have the right to opt-out and not be
            bound by the arbitration and class action waiver provisions set
            forth in the preceding paragraphs by sending written notice of your
            decision to opt-out to the following address:
          </li>
        </ul>
        <p>Big Save Plus Venture 206 Chapman Rd 205 #4 Newark, DE 19702</p>
        <p>
          The notice must be sent within thirty (30) days of the date these
          Terms became effective or your first use of the Services, whichever
          comes later, otherwise you shall be bound to arbitrate disputes in
          accordance with the terms of those paragraphs. If you opt-out of these
          arbitration provisions, BigSavePlus also will not be bound by them.
        </p>
        <p>
          Exception - Litigation of Intellectual Property and Small Claims Court
          Claims. Notwithstanding the parties decision to resolve all disputes
          through arbitration, either party may bring enforcement actions,
          validity determinations or claims arising from or relating to theft,
          piracy or unauthorized use of intellectual property in state or
          federal court or in the U.S. Patent and Trademark Office to protect
          its intellectual property rights ("intellectual property rights" means
          patents, copyrights, moral rights, trademarks, and trade secrets, but
          not privacy or publicity rights). Either party may also seek relief in
          a small claims court for disputes or claims within the scope of that
          courts jurisdiction.
        </p>
        <p>
          Exclusive Venue for Litigation. Solely to the extent the arbitration
          provisions set forth above do not apply, or for purposes of either
          party seeking injunctive relief or for enforcing an award granted to
          it pursuant to arbitration, the parties agree that any litigation
          between them shall be filed exclusively in the federal district court
          for the Northern District of California, or, if federal subject matter
          jurisdiction is lacking, then in the state courts located in Newyark,
          Deleware. The parties expressly hereby consent to exclusive
          jurisdiction in the aforesaid courts for any litigation, hereby also
          consent to personal jurisdiction in said courts for any litigation and
          waive, for all purposes, their right to challenge the lack of personal
          jurisdiction said courts over any litigation arising in connection
          with, out of, or as a result of (a) these Terms or the Services, and
          (b) any acts or omissions of the Company in connection with these
          Terms or the Services.
        </p>
        <p>
          Any claim or cause of action you may have arising out of or relating
          to these Terms or the Services must be commenced within one (1) year
          after the cause of action accrues, otherwise, such cause of action or
          claim is permanently barred.
        </p>
        <p>
          The parties specifically exclude from application to these Terms the
          United Nations Convention on Contracts for the International Sale of
          Goods and the Uniform Computer Information Transactions Act. If you
          reside outside the United States, nothing in these Terms shall, to the
          extent required by applicable law that cannot be excluded by
          agreement, affect any rights you may have under existing consumer
          protection laws, unfair competition laws or other applicable laws of
          the country in which you reside, including any right you have to bring
          a claim in the courts of your home country.
        </p>
        <p>
          Changes to This Section - BigSavePlus will provide thirty (30) days
          notice of any changes affecting the substance of this Arbitration and
          Class Action section by posting on our website, sending you a message,
          or otherwise notifying you. Amendments will become effective thirty
          (30) days after they are posted on the website or sent to you.
        </p>
        <p>
          Changes to this section will otherwise apply prospectively only to
          claims arising after the thirtieth (30th) day. If a court or
          arbitrator decides that this subsection on "Changes to This Section"
          is not enforceable or valid, then this subsection shall be severed
          from the section entitled "Arbitration and Class Action Waiver," and
          the court or arbitrator shall apply the first Arbitration and Class
          Action Waiver section in existence after you enrolled in
          bigsaveplus.com Texts.
        </p>
        <p>
          Survival - This Arbitration and Class Action Waiver section shall
          survive any termination of these Terms, your use of the Services or
          your account.
        </p>
        <h6>20. COMPLIANCE WITH LAWS</h6>
        <p>
          You agree not to use the Services in violation of any applicable law,
          rule, or regulation or any third party right (including but not
          limited to intellectual property rights). You agree to comply with all
          export laws, restrictions, and regulations of the United States or
          your country of residence.
        </p>
        <h6>21. GENERAL</h6>
        <p>
          These Terms and any other electronic policies and guidelines
          referenced and thereby incorporated herein will be deemed to be in
          compliance with applicable statutory, contractual, and other legal
          requirements for writing and be legally enforceable as a signed
          writing as against the parties, and deemed an "original" and "in
          writing" when printed from electronic records established and
          maintained in the ordinary course of business. If any portion of these
          Terms is held invalid, you agree that such invalidity will not affect
          the validity of the remaining portions of these Terms. No waiver by
          BigSavePlus of any breach or default of these Terms will constitute a
          continuing waiver of such breach or default or be deemed to be a
          waiver of any preceding or subsequent breach or default. These Terms
          represent the complete agreement between BigSavePlus and you regarding
          the subject matter set forth herein and supersedes all prior
          agreements and representations between BigSavePlus and you.
        </p>
        <h6>22. COPYRIGHT & TRADEMARK INFRINGEMENT REPORTING PROCEDURES</h6>
        <p>
          BigSavePlus will respond to allegations of copyright infringement in
          accordance with the Digital Millennium Copyright Act and allegations
          of trademark infringement in accordance with our trademark policy.
        </p>
        <h6>23. CONTACT INFORMATION</h6>
        <h6>
          If you have any questions regarding these Terms, please contact us by
          mail at:
        </h6>
        <p>
          Big Save Plus Venture 206 Chapman Rd 205 #4 Newark, DE 19702
          <p>Attn: Terms of Use</p>
        </p>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
