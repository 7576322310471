import Coupon from "../assets/coupon.png";
import { background } from "./common";

const Hero = () => {
  return (
    <div
      className="d-flex flex-lg-row flex-column-reverse justify-content-lg-around justify-content-center align-items-center"
      style={{ padding: "100px 0px", background: background.hero }}
    >
      <div
        className="d-flex flex-column text-lg-start text-center lg:mt-0 mt-5"
        style={{ maxWidth: "500px" }}
      >
        <div className="fs-1 fw-bold">
          Save Big with Exclusive Coupons and Deals!
        </div>
        <div className="fw-normal fs-5" style={{ maxWidth: "500px" }}>
          Discover the Best Discounts on Your Favorite Brands and Save Money
          Every Time You Shop.
        </div>
        <div class="lg:mt-0 mt-4">
          <a href="#hot">
            <button className="btn btn-primary">Get Started</button>
          </a>
        </div>
      </div>
      <div>
        <img
          className="image-class"
          src={Coupon}
          alt="man"
          style={{ height: "50vh", width: "100%" }}
        />
      </div>
    </div>
  );
};

export default Hero;
