function validatePhoneNumber(phoneNumber, callingCode) {
    // Step 1: Separate the calling code and the phone number
    const fullNumber = phoneNumber?.startsWith('+') ? phoneNumber?.substring(1) : phoneNumber;
    const actualNumber = fullNumber.substring(callingCode.length);

    // Step 2: Validate the actual number
    const regex = /^\d{11}$/; // Adjust regex as needed
    const isValid = regex.test(actualNumber);

    // Return both parts and validity
    return isValid
}

export default validatePhoneNumber