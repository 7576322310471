import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { createContext, useContext, useEffect, useState } from "react";
// import initializeAndFetchVisitorId from "../utils/fingerprint";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [validating, setValidating] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [offerpercent, setOfferpercent] = useState("");
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState();
  const [countryCode, setCountryCode] = useState();
  const [regionName, setRegionName] = useState();
  const [city, setCity] = useState();
  const [timezone, setTimezone] = useState();
  const [isp, setIsp] = useState();
  const [callhash, setCallhash] = useState(true);
  //new Entry
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [fingerprint, setFingerprint] = useState("");
  const [firstSubmission, setFirstSubmission] = useState("");
  const [ip, setIP] = useState("");
  const [lastSubmission, setLastSubmission] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [product_hash, setProduct_hash] = useState("");
  const [refererWebsite, setRefererWebsite] = useState("");
  const [submissions, setSubmissions] = useState("");
  const [user_id, setUser_id] = useState("");
  const [blockType, setBlockType] = useState("");
  const [blockStart, setBlockStart] = useState("");
  const [blockEnd, setBlockEnd] = useState("");
  const [blockCount, setBlockCount] = useState("");
  const [image, setImage] = useState(undefined);
  const [fromNumber, setFromNumber] = useState("");
  const [redirecturl, setRedirecturl] = useState("https://google.com");
  const [isDisable, setIsDisable] = useState(false);

  const submission = {
    country: country,
    email: email,
    fingerprint: fingerprint,
    firstSubmission: firstSubmission,
    ip: ip,
    lastSubmission: lastSubmission,
    phoneNumber: phoneNumber,
    refererWebsite: refererWebsite,
    submissions: submissions,
  };
  // const fetchVisitorId = async () => {
  //   if (window.FingerprintJS) {
  //     const fp = await window.FingerprintJS.load();
  //     const result = await fp.get();
  //     const id = result.visitorId;
  //     setFingerprint(id);
  //   } else {
  //     console.error("FingerprintJS is not available.");
  //   }
  // };

  useEffect(() => {
    const hash = async () => {
      getCurrentBrowserFingerPrint().then((fingerprint) => {
        // fingerprint is your unique browser id.
        // This is well tested
        setFingerprint(fingerprint.toString());
        // the result you receive here is the combination of Canvas fingerprint and audio fingerprint.
      });
    };

    hash();
  }, [callhash]);

  const values = {
    image,
    setImage,
    fromNumber,
    setFromNumber,
    product_hash,
    setProduct_hash,
    country,
    setCountry,
    email,
    setEmail,
    fingerprint,
    setFingerprint,
    firstSubmission,
    setFirstSubmission,
    ip,
    setIP,
    lastSubmission,
    setLastSubmission,
    phoneNumber,
    setPhoneNumber,
    refererWebsite,
    setRefererWebsite,
    submissions,
    setSubmissions,
    user_id,
    setUser_id,
    blockType,
    setBlockType,
    blockStart,
    setBlockStart,
    blockEnd,
    setBlockEnd,
    blockCount,
    setBlockCount,
    callhash,
    setCallhash,
    id,
    status,
    setStatus,
    countryCode,
    setCountryCode,
    regionName,
    setRegionName,
    city,
    setCity,
    timezone,
    setTimezone,
    setIsp,
    isp,
    setId,
    location,
    setLocation,
    data,
    setData,
    validating,
    setValidating,
    couponCode,
    setCouponCode,
    offerpercent,
    setOfferpercent,
    redirecturl,
    setRedirecturl,
    isDisable,
    setIsDisable,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
