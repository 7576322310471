import axios from "axios";
import { useState, useContext, createContext, useEffect } from "react";

const couponContext = createContext({});

export const useCoupon = () => useContext(couponContext);

const CouponProvider = ({ children }) => {
  const [coupons, setCoupons] = useState([]);
  const [call_conf_ans_function, setCall_conf_ans_function] = useState(null);
  const [submit_function, setSubmit_function] = useState(null);
  const [loaderAtpremium, setLoaderAtpremium] = useState(false);
  const [loaderAtgeneral, setLoaderAtgeneral] = useState(false);
  const [twilioResponse, setTwilioResponse] = useState(false);
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  const fetchAllCoupons = async () => {
    try {
      const res = await axios.get("https://apiz.adam-app.xyz/v1/coupons");
      setCoupons(res?.data?.data);
      setLoaderAtgeneral(true);
    } catch (error) {
      // TODO: Handle error 
    }
  };

  useEffect(() => {
    fetchAllCoupons();
  }, []);
  const values = {
    coupons,
    setCoupons,
    call_conf_ans_function,
    setCall_conf_ans_function,
    submit_function,
    setSubmit_function,
    loaderAtpremium,
    setLoaderAtpremium,
    loaderAtgeneral,
    setLoaderAtgeneral,
    twilioResponse,
    setTwilioResponse,
    showHeaderFooter,
    setShowHeaderFooter,
  };
  return (
    <couponContext.Provider value={values}>{children}</couponContext.Provider>
  );
};
export default CouponProvider;
