const Card = ({ image }) => {
  return (
    <>
      <div
        className="cards"
        style={{
          width: "300px",
          display: "flex",
          background: "white",
          margin: "10px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <img
          src={image}
          alt="logo"
          style={{ height: "116px", width: "168px", marginTop: "20px" }}
        />
        <div style={{ color: "#3c52ff", fontWeight: 600, margin: "20px 0px" }}>
          Get Deal
        </div>
      </div>
    </>
  );
};

export default Card;
