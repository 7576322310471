import React from "react";
import Card from "./Card";
import { background } from "./common";
import { Link } from "react-router-dom";
import { useCoupon } from "../context/couponContext";

export const Deal = () => {
  const { coupons } = useCoupon();
  return (
    <section id="deal" className="py-4" style={{ background: background.deal }}>
      <div
        className="fw-bold fs-2 d-flex row justify-content-center py-4"
        style={{ paddingTop: "100px" }}
      >
        Deal of the Day
      </div>
      <center>
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-3 ">
            {coupons.slice(16, 25).map((item) => {
              const image = require("../companies/" + item.image);
              return (
                <div className="col">
                  <Link
                    to={`/offer/${item.name}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card image={image} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </center>
    </section>
  );
};
