import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  const year = new Date().getFullYear();
  const footerLinks = [
    { name: 'Terms & Condition', path: '/terms-and-condition' },
    { name: 'Privacy', path: '/privacy' },
    { name: 'Disclosure', path: '/disclosure' },
  ];

  const linkStyle = { textDecoration: 'none', color: 'black' };
  const flexRowStyle = { fontSize: '12px' };
  const disclaimerStyle = { fontSize: '0.5em' };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center border-top">
      <div className="d-flex flex-row gap-2 mt-4" style={flexRowStyle}>
        {footerLinks.map((link, index) => (
          <div key={index}>
            <Link to={link.path} style={linkStyle}>
              {link.name}
            </Link>
          </div>
        ))}
      </div>
      <div>© {year} All rights reserved - Big Save Plus</div>
      <Container>
        <div className="text-center py-2" style={disclaimerStyle}>
          All brands and offers displayed on this website are the property of
          their respective brand owners. All rights are reserved. Any
          third-party trademarks mentioned belong to their respective owners,
          who retain all property rights. The presence of a third-party
          trademark on this website does not imply any affiliation between
          Bigsaveplus and that particular third-party, nor does it suggest the
          endorsement of Bigsaveplus or its services by the third-party.
        </div>
      </Container>
    </div>
  );
};

export default Footer;
